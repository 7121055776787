import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import Toolbar from "@mui/material/Toolbar";

import { Collapse, createTheme, ThemeProvider } from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../assets/images/AW_KBANK_2_Logo_Navigation.png";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 900,
      lg: 1024,
      xl: 1536,
    },
  },
});
export default function Navbar() {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  useEffect(() => {
    window.addEventListener("resize", (e: any) => {
      if (e.target.outerWidth > 1023) {
        setMobileOpen(false);
      }
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", width: "100%" }}>
        <CssBaseline />
        <AppBar
          component="nav"
          sx={{
            background: "#0B2A33E5",
            minHeight: {
              xs: "76px !important",
              sm: "76px !important",
              md: "76px !important",
              lg: "96px !important",
            },
            display: "flex",
            justifyContent: "center",
            width: "100%",
            top: "0 !important",
            borderRadius: {
              xs: mobileOpen ? "0 0 30px 30px" : "",
              sm: mobileOpen ? "0 0 30px 30px" : "",
              md: mobileOpen ? "0 0 30px 30px" : "",
              lg: "",
            },
          }}
          position="fixed"
        >
          <Toolbar sx={{ padding: "0 !important" }}>
            <div className="flex flex-row justify-end laptop:justify-between items-center w-full">
              <div className="hidden laptop:flex items-center px-8  w-[160px] tablet:w-[185px] h-[56px] tablet:h-[96px]">
                <Link to="/" className={` w-full`}>
                  <img
                    src={Logo}
                    alt=""
                    className=" h-[80px] tablet:h-[100px] w-full"
                  />
                </Link>
              </div>
              <div className="hidden laptop:flex flex-row   pr-12 h-[56px] tablet:h-[96px] ">
                <div className="  text-lg uppercase flex items-center font-['DB-Heavent'] gap-4 desktop:gap-8 ">
                  <a
                    href="/#about"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    About
                  </a>
                  <a
                    href="/#speakers"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    Speakers
                  </a>
                  <a
                    href="/#startup"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    JUMP Startup Clinic
                  </a>

                  <NavLink
                    to="/agenda"
                    preventScrollReset={true}
                    onClick={() => {
                      document.body.scrollTop = 0;
                      document.documentElement.scrollTop = 0;
                    }}
                    className={" text-[1.4rem]"}
                  >
                    Agenda
                  </NavLink>
                  <a
                    href="/#exhibitors"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    Exhibitors
                  </a>
                  {/* <a href="/#exhibitors" className="text-[1.4rem]" onClick={()=>setMobileOpen(false)}>
                    Exhibitors
                  </a> */}



                  <div className="flex items-center justify-center">
                    <Link
                      to="https://www.zipeventapp.com/e/Earth-Jump-2023-New-Frontier-of-Growth"
                      className="flex justify-center items-center text-2xl text-white min-w-[246px] h-[42px] text-center py-1  bg-link font-bold  rounded-full font-['DB-Heavent'] "
                    >
                      ลงทะเบียนเข้าร่วมงาน
                    </Link>
                  </div>
                </div>
              </div>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{
                  mr: 2,
                  display: { lg: "none" },
                  transform: !mobileOpen ? "rotate(180deg)" : "",
                  transition: "300ms",
                }}
              >
                {!mobileOpen ? <MenuIcon /> : <CloseIcon />}
              </IconButton>
            </div>
          </Toolbar>
          <Collapse
            in={mobileOpen}
            timeout={{ appear: 550, enter: 550, exit: 550 }}
          >
            <Box
              sx={{
                padding: "2rem",
                paddingTop: "0rem",
                width: "100%",
              }}
            >
              <List
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <ListItem
                  disablePadding
                  sx={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "28px",
                    width: "80%",
                    padding: "1rem",
                  }}
                >
                  <a
                    href="/#about"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    About
                  </a>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "28px",
                    width: "80%",
                    padding: "1rem",
                  }}
                >
                  <a
                    href="/#speakers"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    Speakers
                  </a>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "28px",
                    width: "80%",
                    padding: "1rem",
                  }}
                >
                  <a
                    href="/#startup"
                    className="text-[1.4rem]"
                    onClick={() => setMobileOpen(false)}
                  >
                    JUMP Startup Clinic
                  </a>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "28px",
                    width: "80%",
                    padding: "1rem",
                  }}
                >
                  <NavLink
                    to="/agenda"
                    preventScrollReset={true}
                    onClick={() => {
                      document.body.scrollTop = 0;
                      document.documentElement.scrollTop = 0;
                      setMobileOpen(false);
                    }}
                    className={" text-[1.4rem]"}
                  >
                    Agenda
                  </NavLink>
                </ListItem>
                <ListItem
                  disablePadding
                  sx={{
                    borderBottom: "1px solid white",
                    textAlign: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontSize: "28px",
                    width: "80%",
                    padding: "1rem",
                  }}
                >
                  <a href="/#exhibitors" className="text-[1.4rem]" onClick={()=>setMobileOpen(false)}>
                    Exhibitors
                  </a>
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </AppBar>
      </Box>
    </ThemeProvider>
  );
}
