import React, { Suspense } from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "./routes/root";
const HomePage = React.lazy(() => import("./page/home-page"));
const AgendaPage = React.lazy(() => import("./page/agenda-page"));
const Loading = () => {
  return <div className="bg-[#061d28] w-full h-[100vh]">Loading...</div>;
};
function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Root />,
      children: [
        {
          path: "/",
          element: (
            <Suspense fallback={<Loading />}>
              <HomePage />
            </Suspense>
          ),
        },
        {
          path: "/agenda",
          element: (
            <Suspense fallback={<Loading />}>
              <AgendaPage />
            </Suspense>
          ),
        },
      ],
    },
  ]);
  return (
    <div className="App overflow-x-hidden">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
