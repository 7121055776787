import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../components/navbar/navbar";

export default function Root() {
  const location = useLocation();
  return (
    <div
      className={`${
        location.pathname === "/agenda"
          ? "h-[100vh] overflow-hidden"
          : "overflow-x-hidden"
      }`}
    >
      <Navbar />
      <div id="content" className="font-['DB-Heavent'] ">
        <Outlet />
      </div>
    </div>
  );
}
